"use strict";
import { v4 as uuidv4 } from 'uuid';
import Constant from './Constant';
import AxiosService from './AxiosService';
import Utils from './Utils';

var runAsync = require('run-async');
var self = this;



var addAuditLog = function (data) {
    try {
        runAsync.cb(function (data, cb) {
            cb(null, AxiosService.post(Constant.url.AUDIT_URI, data));
        }, function (err, result) {
            console.log(result)
        })(data);
    } catch (error) {
        console.error(error);
    }
}

var initalizedData = function (name, action, data) {
    var auditData = {
        "id": 0,
        "name": name,
        "action": action,
        "uuid": Utils.uuid(),
        "requestDate": "",
        "userId": localStorage.getItem(Constant.data.USER_ID),
        data: data
    }
    return auditData;
}

var userAuditTemplate = function (data, groupName) {
    var userTemplate = {
        "User Id": Utils.isEmptyStr(data) ? $('#userId').val() : data.userId,
        "User Name": Utils.isEmptyStr(data) ? $('#userName').val() : data.userName,
        "Customer": Utils.isEmptyStr(data) ? $("#customerId option:selected").text() : data.customerName,
        "Group": Utils.isEmptyStr(data) ? groupName : data.roleName + ' : ' + data.groupName
    }
    return JSON.stringify(userTemplate);
}

var groupAuditTemplate = function (data) {
    var groupTemplate = {
        "Group Name ": data.name,
        "Description ": data.description,
    }
    return JSON.stringify(groupTemplate);
}

var customerAuditTemplate = function (data) {
    var customerTemplate = {
        "Customer Name": data.name,
        "Description": data.description,
        "Authorization Method": data.authMethod,
    }
    return JSON.stringify(customerTemplate);
}

var propertyAuditTemplate = function (data) {
    var propertyTemplate = {
        "Category": data.category,
        "Property Name": data.propertyName,
        "Property Value": data.propertyValue,
    }
    return JSON.stringify(propertyTemplate);
}


var apiEndpointAuditTemplate = function (data) {
    var apiEndpointTemplate = {
        "Name": data.name,
        "URL": data.url,
        "Response Timeout": data.responseTimeout,
        "Http Timeout": data.httpTimeout,
        "URL Version": data.urlVersion,
        "API Provider": data.apiProvider,
        "Config Key": data.configKey,
    }
    return JSON.stringify(apiEndpointTemplate);
}

var apiDefinitionTemplate = function (data) {
    var apiDefinitionTemplate = {
        "Name": data.name,
        "Description": data.description,
        "Type": data.type,
        "Method": data.method,
        "Content": Utils.isEmptyStr(data.content) ? data.definitionFile : data.content,
        "Service Provider": data.serviceProvider,
        "Endpoint": data.endpoint,
    }
    return JSON.stringify(apiDefinitionTemplate);
}

var roleTemplate = function (data, group, selectedMenu, selectedApi) {
    var roleTemplate = {
        "Name": data.name,
        "Description": data.description,
        "API Provider": data.apiName,
        "Group": Utils.isEmptyStr(group) ? data.group : group,
        "Menu Privilage": selectedMenu,
        "API Privilage": selectedApi
    }
    return JSON.stringify(roleTemplate);
}

export default {
    addAuditLog,
    initalizedData,
    userAuditTemplate,
    groupAuditTemplate,
    customerAuditTemplate,
    propertyAuditTemplate,
    apiEndpointAuditTemplate,
    apiDefinitionTemplate,
    roleTemplate
}